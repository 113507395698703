import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SearchService } from '../../shared/services/search.service';
import { Content } from '../../core/content/content';
import { EntityService } from '../../shared/services/entity.service';
import { ArticleImSearchService } from '../../shared/services/article-im-search.service';
import { Subscription } from 'rxjs';
import { PublicationSource } from '../search/publication-source';
import { first } from 'rxjs/operators';
import { UrlService } from '../../shared/services/url.service';
import { LoaderService } from '../../common-explain/services/loader.service';
import { ApiService } from '../../shared/services/api/api.service';
import { AdminDocSourceEnum, ProjectDocumentSourceEnum, RegionDepaDocumentSourceEnum } from '../../models/admin-document';

@Component({
  selector: 'app-admin-docs-page',
  templateUrl: './admin-docs-page.component.html',
  styleUrls: ['./admin-docs-page.component.scss']
})
export class AdminDocsPageComponent extends Content implements OnInit, OnDestroy {
  @ViewChild('adminDoc') adminDocElement!: ElementRef;
  adminDocId = 'adminDoc';
  nbTotalAdminDoc!: number;
  nbTotalWebsite!: number;
  docId: string | null = null;

  /** Subscriptions to services */
  getEntityIdSubscription!: Subscription;
  entityServiceSubscription!: Subscription;

  constructor(
    protected override searchService: SearchService,
    protected override entityService: EntityService,
    protected override articleImSearchService: ArticleImSearchService,
    private apiService: ApiService,
    private urlService: UrlService,
    private loaderService: LoaderService
  ) {
    super(searchService, entityService, articleImSearchService);

  }

  ngOnInit() {

    this.apiService.exStatistics.ExplainStatisticsReplaySubject
      .pipe(first())
      .subscribe((stats) => {
        this.nbTotalAdminDoc = stats.allAdminDocsCount;
        this.nbTotalWebsite = stats.allWebsitesCount;
      });

    this.adminDocId = 'adminDoc' + this.searchService.SearchModeMap.get(PublicationSource.ADMIN_DOCS);

    this.getEntityIdSubscription =
      this.entityService.getEntityId().subscribe(docId => {
        this.docId = docId;
      });

    this.searchService.sentPageIndex(1);
    this.getSearch();

    // ADD event listener to store the current scrollTop value in the adminDoc component.
    setTimeout(() => {
      // this.adminDocElement.nativeElement.addEventListener(() => {
      (document.getElementById(this.adminDocId) as HTMLElement).addEventListener(
        'scroll',
        () => {
          this.scrollHandler();
        });
    }, 300);

    this.entityServiceSubscription = this.entityService.BookMarkPathsSubject
      .subscribe(({id, bookmarkPaths}) => {
        if (this.entities) {
          const updatedBookmarkPathsIndex = this.entities.findIndex(obj => obj['id'] === id);
          this.entities[updatedBookmarkPathsIndex]['bookmark_paths'] = bookmarkPaths;
        }
      });


  }

  scrollHandler() {
    const currentScroll = document.getElementById(this.adminDocId)?.scrollTop;
    // Set value of currentScroll in storing map
    const searchMode =this.searchService.SearchModeMap.get(PublicationSource.ADMIN_DOCS);
    if(currentScroll && searchMode) {
      this.searchService.SearchOffsetMap.get(PublicationSource.ADMIN_DOCS)
        ?.set(searchMode, currentScroll);
    }
  }


  ngOnDestroy() {
    /** Unsubscribe of the unherited searchSubscription from Content */
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }

    /** Unsubscribe of subscriptions */
    if (this.getEntityIdSubscription) {
      this.getEntityIdSubscription.unsubscribe();
    }

    if (this.entityServiceSubscription) {
      this.entityServiceSubscription.unsubscribe();
    }

    // Unsubscribe of the event listener
    if (document.getElementById('adminDoc0')) {
      (document.getElementById(this.adminDocId) as HTMLElement).removeEventListener('scroll', () => this.scrollHandler());
    }
    if (document.getElementById('adminDoc1')) {
      (document.getElementById(this.adminDocId) as HTMLElement).removeEventListener('scroll', () => this.scrollHandler());
    }

    this.searchService.currentPage = 1;
  }

  /** Overriding abstact method retrieveData declared in Content */
  retrieveData(search: any): void {
    if (this.urlService.evaluateIfComingFromOpenedDocument()) { // When coming from article | document, no data retieved.
      return;
    }
    this.entityService.sendNbResource(search['filters']['resources_count']);
    const body = this.buildBody(search);
    this.entityService.sendTotalCount(this.totalCount);
    const searchMode = this.searchService.SearchModeMap.get(PublicationSource.ADMIN_DOCS)

    this.request(this.apiService.adminDoc.retrieveAdminDocs(body, this.searchService.SearchModeMap.get(PublicationSource.ADMIN_DOCS))
      .pipe(first())
      .subscribe(
        (data: any) => {
          const scrollTop = searchMode ? this.searchService.SearchOffsetMap?.get(PublicationSource.ADMIN_DOCS)?.get(searchMode) : null;
          this.adminDocElement.nativeElement.scrollTo({
            top: scrollTop
          });
          const bodyCount = this.buildCountBody(search);
          this.apiService.adminDoc.retrieveAdminDocsCount(bodyCount)
            .pipe(first())
            .subscribe(
              (adminDocCountData: any) => {
                this.setEntities(data, search['limit'], search['offset']);
                this.affectDisplayHelperObjectsToEntities();
                this.entityService.sendTotalCount(this.totalCount);
                this.entityService.sendAllCount(adminDocCountData['count']);
              });
        },
        _ => {
          this.totalCount = 0;
          this.entityService.sendTotalCount(this.totalCount);
          this.entityService.sendAllCount(0);
          this.loaderService.hideLoader(true);
        }));
  }

  /** method utile pour créer un objet d'aide à l'affichage de nos entités */
  affectDisplayHelperObjectsToEntities() {
    affectDisplayHelperObjectsToEntities(
        this.entities as Array<{source: string, project_uid?: string, data_provider: string, display_helper: object }>
    );
  }

}

export function affectDisplayHelperObjectsToEntities(
    entities: Array<{source: string, project_uid?: string, data_provider: string, display_helper: object }>
) {
  entities.forEach((item) => {
    setRedirectsToUrl(item);
    affectDisplayHelperObjectsToEntity(item);
  });
}

export function affectDisplayHelperObjectsToEntity(
    entity: {source: string, project_uid?: string, data_provider: string, display_helper: object }
) {
  // 1. case admin doc
  const display_helper: DbDisplayHelperForDocumentEntity = {
    card_type: '',
    icon_name: '',
    text: ''
  };
  // case regular admin doc and syndicates
  if ([AdminDocSourceEnum.FRCOMM, AdminDocSourceEnum.FREPCI, AdminDocSourceEnum.EPTP, AdminDocSourceEnum.SYNDICAT]
      .includes(entity.source as AdminDocSourceEnum)) {
    display_helper.card_type = 'admin-doc';
    display_helper.icon_name = 'small-territory';
    display_helper.text = entity.source;
    // case pref and mrae
  } else if ([ProjectDocumentSourceEnum.MRAE, ProjectDocumentSourceEnum.PREFECTURE].includes(entity.source as ProjectDocumentSourceEnum)) {
    display_helper.card_type = 'pref-and-mrae';
    display_helper.icon_name = 'authorize-stamp';
    display_helper.text = entity.source;
    // case region and department
  } else if ([RegionDepaDocumentSourceEnum.FRDEPA, RegionDepaDocumentSourceEnum.FRREGI]
      .includes(entity.source as RegionDepaDocumentSourceEnum)) {
    display_helper.card_type = 'region-and-department';
    display_helper.icon_name = 'big-territory';
    display_helper.text = entity.source;
    // default case
  } else {
    display_helper.card_type = 'admin-doc';
    display_helper.icon_name = 'city-basic-800';
    display_helper.text = 'undetected';
  }
  // on affecte l'objet d'aide à l'affichage à l'entity
  entity.display_helper = display_helper;
}

export function setRedirectsToUrl(entity: any) {
  entity['redirects_to_url'] = (entity['source'] === 'PREF') &&
      (entity['publication_type'][0] === 'avis_enquete_publique');
}

export interface DbDisplayHelperForDocumentEntity {
  card_type: string;
  icon_name: string;
  text: string;
}
