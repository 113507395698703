import { DbSourceStepSection, TerritoryWatchIssuerKindEnum } from '../../models/territory-watch-settings';
import { TreeviewConfig, TreeviewItem } from '../../lib/ngx-treeview';

// To come in the near future
// export enum IssuerGroupNameEnum {
//   PRESS = 'press',
//   PREF_AND_MRAE = 'pref-and-mrae',
//   SYNDICATES = 'syndicates',
//   COMM_AND_EPCI = 'comm-and-epci'
// }
//
// export enum SourceStepSectionNameEnum {
//   ARTICLES = 'articles',
// }

export interface DbDefaultIssuerGroupData {
  name: string;
  publicationTypes: Array<TerritoryWatchIssuerKindEnum>;
  title: string;
  subTitle: string;
  containsTreeview: boolean;
  isAccordion: boolean;
  config: TreeviewConfig;
  selectedItems: Array<TreeviewItem>;
  checked: boolean;
  issuerTemplateClassName: string;
}

/** Classe pour pouvoir utiliser un constructeur */
export class DefaultIssuerGroupData {
  name: string;
  publicationTypes: Array<TerritoryWatchIssuerKindEnum>;
  title: string;
  subTitle: string;
  containsTreeview: boolean;
  isAccordion: boolean;
  treeviewItems: Array<TreeviewItem>;
  config: TreeviewConfig;
  selectedItems: Array<TreeviewItem>;
  checked: boolean;
  itemsStore: Array<TreeviewItem>;
  issuerTemplateClassName: string;


  constructor(CONSTANT: DbDefaultIssuerGroupData,
              checked: boolean,
              treeviewItems: Array<TreeviewItem>,
              itemsStore: Array<TreeviewItem>,
              selectedItems: Array<TreeviewItem>) {
    this.name = CONSTANT.name;
    this.publicationTypes = CONSTANT.publicationTypes;
    this.title = CONSTANT.title;
    this.subTitle = CONSTANT.subTitle;
    this.containsTreeview = CONSTANT.containsTreeview;
    this.isAccordion = CONSTANT.isAccordion;
    this.issuerTemplateClassName = CONSTANT.issuerTemplateClassName;
    this.config = CONSTANT.config;

    this.checked = checked || false;
    this.treeviewItems = treeviewItems || [];
    this.itemsStore = itemsStore || [];
    this.selectedItems = selectedItems || [];
  }
}

export const DEFAULT_PRESS_ISSUER_GROUP_DATA = {
  name: 'press',
  publicationTypes: [TerritoryWatchIssuerKindEnum.PRESS],
  title: 'alert.issuer-kind.press-title',
  subTitle: 'alert.issuer-kind.press-subtitle',
  containsTreeview: false,
  isAccordion: false,
  treeviewItems: [],
  config: TreeviewConfig.create(),
  selectedItems: [],
  checked: true,
  itemsStore: [],
  issuerTemplateClassName: 'list-element'
};

export const DEFAULT_PROJECT_DOCS_ISSUER_GROUP_DATA = {
  name: 'pref-and-mrae',
  publicationTypes: [TerritoryWatchIssuerKindEnum.PREFECTURE, TerritoryWatchIssuerKindEnum.MRAE],
  title: 'alert.issuer-kind.pref-and-mrae-title',
  subTitle: 'alert.issuer-kind.pref-and-mrae-subtitle',
  containsTreeview: true,
  isAccordion: true,
  config: TreeviewConfig.create(),
  selectedItems: [],
  checked: true,
  issuerTemplateClassName: 'list-element-first'
};

export const DEFAULT_REGION_DEPARTMENT_ISSUER_GROUP_DATA = {
  name: 'region-and-department',
  publicationTypes: [TerritoryWatchIssuerKindEnum.FRREGI, TerritoryWatchIssuerKindEnum.MRAE],
  title: 'alert.issuer-kind.region-and-department-title',
  subTitle: 'alert.issuer-kind.region-and-department-subtitle',
  containsTreeview: true,
  isAccordion: true,
  config: TreeviewConfig.create(),
  selectedItems: [],
  checked: true,
  issuerTemplateClassName: 'list-element'
};

export const DEFAULT_SYNDICATES_ISSUER_GROUP_DATA = {
  name: 'syndicates',
  publicationTypes: [TerritoryWatchIssuerKindEnum.SYNDICAT],
  title: 'alert.issuer-kind.syndicates-title',
  subTitle: 'alert.issuer-kind.syndicates-subtitle',
  containsTreeview: true,
  isAccordion: true,
  treeviewItems: [],
  config: TreeviewConfig.create(),
  selectedItems: [],
  checked: true,
  itemsStore: [],
  issuerTemplateClassName: 'list-element'
};

export const DEFAULT_COMM_AND_EPCI_ISSUER_GROUP_DATA = {
  name: 'comm-and-epci',
  publicationTypes: [TerritoryWatchIssuerKindEnum.FRCOMM, TerritoryWatchIssuerKindEnum.FREPCI],
  title: 'alert.issuer-kind.comm-and-epci-title',
  subTitle: 'alert.issuer-kind.comm-and-epci-subtitle',
  containsTreeview: true,
  isAccordion: true,
  config: TreeviewConfig.create(),
  selectedItems: [],
  checked: true,
  issuerTemplateClassName: 'list-element-last'
};

export const DEFAULT_ARTICLE_SOURCE_STEP_SECTION_DATA: DbSourceStepSection = {
  sectionNumber: 1,
  sectionTitle: 'alert.source-step-section-title.press',
};

export const DEFAULT_ADMIN_DOCS_SOURCE_STEP_SECTION_DATA: DbSourceStepSection = {
  sectionNumber: 2,
  sectionTitle: 'alert.source-step-section-title.admin-docs',
};
